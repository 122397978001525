import {
  Input,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Select,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";

import { useState } from "react";

import { useHistory } from "react-router";
import { useAuth } from "../AuthProvider";

import React from "react";
import * as Yup from "yup";
import {
  withFormik,
  FormikProps,
  FormikErrors,
  Form,
  Field,
  FieldProps,
} from "formik";
import { FaRProject } from "react-icons/fa";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

// Shape of form values
interface FormValues {
  email: string;
  password: string;
}

interface OtherProps {
  message: string;
}

// Aside: You may see InjectedFormikProps<OtherProps, FormValues> instead of what comes below in older code.. InjectedFormikProps was artifact of when Formik only exported a HoC. It is also less flexible as it MUST wrap all props (it passes them through).
const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
  const { touched, errors, isSubmitting, message } = props;
  return (
    <Form>
      <Field type="email" name="email">
        {({ field, form }: FieldProps) => (
          <FormControl
            isRequired
            isInvalid={
              errors.hasOwnProperty("email") && touched.hasOwnProperty("email")
            }
          >
            <FormLabel htmlFor="email">E-mail</FormLabel>
            <Input {...field} id="email" placeholder="email" />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
        )}
      </Field>

      <Field type="password" name="password">
        {({ field, form }: FieldProps) => (
          <FormControl
            isRequired
            isInvalid={
              errors.hasOwnProperty("password") &&
              touched.hasOwnProperty("password")
            }
          >
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              {...field}
              id="password"
              type="password"
              placeholder="password"
            />
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
        )}
      </Field>

      <Button type="submit" mt={4} colorScheme="blue" isLoading={isSubmitting}>
        Log In
      </Button>
    </Form>
  );
};

interface LoginResult {
  success: boolean;
  message: string;
}

// The type of props MyForm receives
interface MyFormProps {
  initialEmail?: string;
  message: string; // if this passed all the way through you might do this or make a union type
  handleLoginResult: (
    result: LoginResult,
    email?: string,
    token?: string
  ) => void;
  history: any;
}

// Wrap our form with the withFormik HoC
const MyForm = withFormik<MyFormProps, FormValues>({
  // Transform outer props into form values
  mapPropsToValues: (props) => {
    return {
      email: props.initialEmail || "",
      password: "",
    };
  },

  // Add a custom validation function (this can be async too!)
  validate: (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};
    if (!values.email) {
      errors.email = "Required";
    }

    return errors;
  },
  validationSchema: LoginSchema,

  handleSubmit: async (values, formikBag) => {
    const loginUrl = `${process.env.REACT_APP_API_URL}/login`;

    try {
      const response = await fetch(loginUrl, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          // 'Content-Type': 'application/json'
          "Content-Type": "application/x-www-form-urlencoded",
        },
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: new URLSearchParams(
          `username=${encodeURIComponent(
            values.email
          )}&password=${encodeURIComponent(values.password)}`
        ),
        // body data type must match "Content-Type" header
      });
      const data = await response.json();

      console.log(data);
      if (data.hasOwnProperty("access_token")) {
        formikBag.props.handleLoginResult(
          {
            success: true,
            message: `Login succeeded.`,
          },
          values.email,
          data.access_token
        );
        sessionStorage.setItem("token", data.access_token);
        setTimeout(() => {
          formikBag.props.history.push("/");
        }, 2000);
      } else {
        formikBag.props.handleLoginResult({
          success: false,
          message: `Login failed.`,
        });
      }
    } catch (error) {
      formikBag.props.handleLoginResult({
        success: false,
        message: `Login failed. (${error})`,
      });
    } finally {
      formikBag.setSubmitting(false);
    }
  },
  // do submitting things
  //   console.log(values);

  //   setTimeout(() => {
  //     formikBag.setSubmitting(false);
  //     formikBag.props.handleLoginResult({
  //       success: true,
  //       message: "Login was successful!",
  //     });
  //   }, 2000);
  // },
})(InnerForm);

interface LoginFormProps {
  login: (auth: string) => void;
}

const LoginForm = ({ login }: LoginFormProps) => {
  const [loginResult, setLoginResult] = useState<LoginResult | undefined>(
    undefined
  );

  const { state, dispatch } = useAuth();

  const history = useHistory();

  return (
    <>
      <MyForm
        message="Log in"
        handleLoginResult={(result, email, token) => {
          if (result.success) {
            login(email as string);

            dispatch({
              type: "update",
              payload: {
                email,
                token,
                verified: false,
              },
            });
          }
          // setLoginResult(result);
        }}
        history={history}
      />
      {loginResult && (
        <Alert status={loginResult.success ? "success" : "error"} mt={4}>
          <AlertIcon />
          <AlertTitle mr={2}>{loginResult.message}</AlertTitle>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setLoginResult(undefined)}
          />
        </Alert>
      )}
    </>
  );
};

export default LoginForm;
