// src/count-context.tsx
import * as React from "react";
type Action =
  | { type: "verify" }
  | { type: "update"; payload: State }
  | { type: "unknown" }
  | { type: "logout" };
type Dispatch = (action: Action) => void;
type State = {
  [key: string]: string | boolean | undefined;
  email: string | undefined;
  token: string | undefined;
  verified: boolean;
};
type AuthProviderProps = { children: React.ReactNode };

const AuthStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function authReducer(state: State, action: Action): State {
  switch (action.type) {
    case "logout": {
      sessionStorage.clear();
      return { email: undefined, token: undefined, verified: false };
    }
    case "update": {
      for (let item of Object.keys(action.payload)) {
        console.log("hello", item);
        sessionStorage.setItem(item, String(action.payload[item]));
      }
      return {
        ...state,
        ...action.payload,
      };
    }
    case "verify":
      return { ...state, verified: true };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = React.useReducer(authReducer, {
    email: sessionStorage.getItem("email"),
    token: sessionStorage.getItem("token"),
    verified: false,
  } as State);

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <AuthStateContext.Provider value={value}>
      {children}
    </AuthStateContext.Provider>
  );
}
function useAuth() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

// async function login(dispatch, user, updates) {
//     dispatch({type: 'start update', updates})
//     try {
//       const updatedUser = await userClient.updateUser(user, updates)
//       dispatch({type: 'finish update', updatedUser})
//     } catch (error) {
//       dispatch({type: 'fail update', error})
//     }
//   }
// }

export { AuthProvider, useAuth };
