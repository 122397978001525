import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Flex, Spacer } from "@chakra-ui/react";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <Box bg="blue.400" p="2" color="white">
        <Flex dir="column">
          <Link as={RouterLink} to="/about">
            About
          </Link>
          <Spacer />
          <p>Copyright &copy; 2021</p>
        </Flex>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
