import { Box, Heading, Button, Flex, Spacer, Center } from "@chakra-ui/react";
import { Satellite } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthProvider";

interface Props {
  logout: () => void;
}

const AppHeader = () => {
  const { state, dispatch } = useAuth();

  return (
    <Flex boxShadow="base" mb="4">
      <Box p="2" m="auto">
        <Link to="/">
          <Heading size="md">Task Trackr</Heading>
        </Link>
      </Box>
      <Spacer />

      {state.email ? (
        <>
          <Box p="2" m="auto">
            <Heading size="sm">{state.email}</Heading>
          </Box>
          <Box p="2">
            <Button
              colorScheme="blue"
              variant="solid"
              size="sm"
              onClick={() => {
                dispatch({ type: "logout" });
              }}
            >
              Log Out
            </Button>
          </Box>
        </>
      ) : (
        <Box p="2">
          <Link to="/register">
            <Button colorScheme="blue" variant="outline" mr="2" size="sm">
              Sign Up
            </Button>
          </Link>
          <Link to="/login">
            <Button colorScheme="blue" variant="solid" size="sm">
              Log In
            </Button>
          </Link>
        </Box>
      )}
    </Flex>
  );
};

export default AppHeader;
