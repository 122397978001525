import { useState, useEffect } from "react";
import { Alert, AlertTitle, Flex, Box, Button } from "@chakra-ui/react";
import { resendVerificationEmail } from "../utilities/Utilities";

import { useAuth } from "../AuthProvider";

const NotVerified = () => {
  const { state, dispatch } = useAuth();
  const [verificationEmailSent, setVerificationEmailSent] = useState<number>();

  useEffect(() => {
    const sessionStorageVerification = sessionStorage.getItem(
      "verificationEmailSent"
    );
    if (sessionStorageVerification)
      setVerificationEmailSent(Number(sessionStorageVerification));
  }, []);

  return (
    <div>
      <Flex direction="column">
        <p>You need to verify your email before you can add or view tasks.</p>
        <Box>
          <Button
            mt={4}
            mb={4}
            onClick={() => state.email && resendVerificationEmail(state.email)}
            disabled={
              verificationEmailSent
                ? Date.now() - verificationEmailSent < 1000 * 60 * 60
                : false
            }
          >
            Resend Verification Email
          </Button>
          {verificationEmailSent ? (
            Date.now() - verificationEmailSent < 1000 * 60 * 60 && (
              <Alert status="info" mt={4}>
                <AlertTitle mr={2}>Verification email sent!</AlertTitle>
              </Alert>
            )
          ) : (
            <></>
          )}
        </Box>
      </Flex>
    </div>
  );
};

export default NotVerified;
