import { Route, Redirect } from "react-router";
import styled from "styled-components";

import TasksWrapper from "./TasksWrapper";
import About from "./About";
import RegistrationForm from "./RegistrationForm";
import LoginForm from "./LoginForm";

import { useAuth } from "../AuthProvider";

const MainWrapper = styled.div`
  width: 60%;
  height: 95%;
`;

const Main = () => {
  const { state, dispatch } = useAuth();

  return (
    <MainWrapper>
      <Route path="/" exact component={TasksWrapper} />
      <Route path="/about" component={About} />
      <Route path="/register">
        {state.token ? <Redirect to="/" /> : <RegistrationForm />}
      </Route>
      <Route
        path="/login"
        render={(props) =>
          state.token ? (
            <Redirect to="/" />
          ) : (
            <LoginForm
              login={(auth: string) => {
                console.log("does this run");
              }}
            />
          )
        }
      />
    </MainWrapper>
  );
};

export default Main;
