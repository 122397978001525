import { TaskType } from "../App";

import { Box, IconButton, Badge, Heading } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat"; // import plugin
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  task: TaskType;
  onRemove(id: number): void;
  toggleReminder(id: number): void;
}

// const Button = styled.button`
//   // border: none;
//   // padding: 2px 2px 0px 2px;
//   // border-radius: 3px;
//   // background-color: rgba(0, 0, 0, 0);
// `;

const Task = ({ task, onRemove, toggleReminder }: Props) => {
  return (
    <Box
      onDoubleClick={() => toggleReminder(task.id)}
      p={4}
      pl={task.reminder ? 2 : 4}
      mt={2}
      mb={2}
      boxShadow="base"
      borderLeftWidth={task.reminder ? 4 : 0}
      borderLeftColor="green.300"
      borderLeftStyle="solid"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="column">
          <Heading size="md">{task.text}</Heading>
          <Box pt={1}>
            <Badge fontSize="0.9em">
              {dayjs(task.dueDate)
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                .format("MMM Do, YYYY [at] h:mma")}
            </Badge>
          </Box>
        </Box>
        <IconButton
          onClick={() => onRemove(task.id)}
          variant="ghost"
          colorScheme="red"
          aria-label="Remove Task"
          size="sm"
          icon={<CloseIcon />}
        />
      </Box>
    </Box>
  );
};

export default Task;
