const resendVerificationEmail = async (email: string) => {
  const resp = await fetch(
    `${process.env.REACT_APP_API_URL}/verification/resend/${encodeURIComponent(
      email
    )}`
  );
  if (resp.status === 200) {
    console.log("verification email sent");
    const timeStamp = Date.now();
    sessionStorage.setItem("VerificationEmailSent", String(timeStamp));
    return timeStamp;
  }
};

export { resendVerificationEmail };
