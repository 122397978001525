import { Link } from "react-router-dom";
import { Button, Heading, Flex, Spacer } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

const About = () => {
  return (
    <>
      <Flex>
        <Heading pb="4" size="md">
          About
        </Heading>
        <Spacer />
      </Flex>
      <Heading pb="4" size="xs">
        Version 1.0.0
      </Heading>
      <Link to="/">
        <Button size="sm" variant="outline" leftIcon={<ArrowBackIcon />}>
          Back
        </Button>
      </Link>
    </>
  );
};

export default About;
