// import logo from './logo.svg';
// import './App.css';
import { useState, useEffect } from "react";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import {
  ChakraProvider,
  Center,
  Heading,
  Button,
  Box,
  Flex,
  Alert,
  AlertTitle,
} from "@chakra-ui/react";

import globalTheme from "./theme/globalTheme";

import styled from "styled-components";
// import GlobalStyle from './theme/globalStyle';
import Footer from "./components/Footer";
import AppHeader from "./components/AppHeader";
import Main from "./components/Main";

import { AuthProvider } from "./AuthProvider";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const AppWrapper = styled.div`
  min-height: 100vh;
`;

const apiURL = "http://localhost:8000";

export interface TaskType {
  id: number;
  text: string;
  dueDate: string;
  reminder: boolean;
}

export interface TaskTypeNoId extends Omit<TaskType, "id"> {}

const fetchTask = async (
  id: number,
  auth: string | null
): Promise<TaskType> => {
  const res = await fetch(`${apiURL}/tasks/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  const data = await res.json();
  return data;
};

function App() {
  // const addTask = async (task: TaskType) => {
  //   setTasks([...tasks, task]);
  // };

  // const removeTaskById = async (id: number) => {
  //   try {
  //     const res = await fetch(`${apiURL}/tasks/${id}`, {
  //       method: "DELETE",
  //     });

  //     if (!res.ok) throw Error(res.statusText);

  //     const newTasks = tasks.filter((task) => task.id !== id);

  //     setTasks(newTasks);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const toggleReminder = async (id: number) => {
  //   try {
  //     const unmodifiedTasks = tasks.filter((task) => task.id !== id);
  //     const task = await fetchTask(id, token);

  //     const res = await fetch(`${apiURL}/tasks/${id}`, {
  //       method: "PATCH",
  //       headers: {
  //         "Content-type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         reminder: !task?.reminder,
  //       }),
  //     });

  //     if (!res.ok) throw Error(res.statusText);

  //     const data = await res.json();

  //     setTasks([...unmodifiedTasks, data]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <ChakraProvider theme={globalTheme}>
            <AppWrapper>
              {/* <GlobalStyle /> */}
              <AppHeader />
              <Center>
                <Main />
              </Center>
              <Footer />
            </AppWrapper>
          </ChakraProvider>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
