import {
  Input,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Switch,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
} from "@chakra-ui/react";

import { TaskType, TaskTypeNoId } from "../App";
import { useAuth } from "../AuthProvider";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { useState, forwardRef } from "react";
import { useQueryClient, useMutation, UseMutationResult } from "react-query";

import React from "react";
import * as Yup from "yup";
import {
  withFormik,
  FormikProps,
  FormikErrors,
  Form,
  Field,
  FieldProps,
} from "formik";

import DatePickerStyle from "../theme/datePickerStyle";

const TaskSchema = Yup.object().shape({
  text: Yup.string().required("Required"),
  reminder: Yup.boolean(),
  dueDate: Yup.date(),
});

// Shape of form values
interface FormValues {
  text: string;
  reminder: boolean;
  dueDate: string;
}

interface OtherProps {
  message: string;
}

type ButtonProps = React.HTMLProps<HTMLButtonElement>;

const ExampleCustomInput = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ value, onClick }, ref) => (
    <Button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </Button>
  )
);

// Aside: You may see InjectedFormikProps<OtherProps, FormValues> instead of what comes below in older code.. InjectedFormikProps was artifact of when Formik only exported a HoC. It is also less flexible as it MUST wrap all props (it passes them through).
const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
  const { touched, errors, isSubmitting, message, setFieldValue } = props;
  const [startDate, setStartDate] = useState(new Date());

  return (
    <Form>
      <Field type="text" name="text">
        {({ field, form }: FieldProps) => (
          <FormControl
            isRequired
            isInvalid={
              errors.hasOwnProperty("text") && touched.hasOwnProperty("text")
            }
          >
            <FormLabel htmlFor="text">Task</FormLabel>
            <Input {...field} id="task" placeholder="Task description..." />
            <FormErrorMessage>{errors.text}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field type="date" name="duedate">
        {({ field, form }: FieldProps) => (
          <FormControl
            isRequired
            isInvalid={
              errors.hasOwnProperty("duedate") &&
              touched.hasOwnProperty("duedate")
            }
          >
            <FormLabel htmlFor="duedate">Due Date</FormLabel>
            <DatePicker
              showTimeSelect
              selected={startDate}
              onChange={(date: Date) => {
                setFieldValue("dueDate", date.toISOString());
                setStartDate(date);
              }}
              dateFormat="yyyy-MM-dd h:mm a"
              customInput={<ExampleCustomInput />}
            />
            <FormErrorMessage>{errors.dueDate}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field type="switch" name="reminder">
        {({ field, form }: FieldProps) => (
          <>
            <FormControl display="flex" alignItems="center" mt={4}>
              <FormLabel htmlFor="reminder">Reminder</FormLabel>
              <Switch
                id="reminder"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(event);
                  setFieldValue("reminder", event.target.checked);
                }}
              />
            </FormControl>
          </>
        )}
      </Field>
      <Button type="submit" mt={4} colorScheme="green" isLoading={isSubmitting}>
        Add Task
      </Button>
    </Form>
  );
};

interface Result {
  success: boolean;
  message: string;
  task?: TaskType;
}

// The type of props MyForm receives
interface MyFormProps {
  message: string; // if this passed all the way through you might do this or make a union type
  handleResult: (result: Result) => void;
  addTaskMutation: UseMutationResult<TaskType, unknown, TaskTypeNoId, unknown>;
}

// Wrap our form with the withFormik HoC
const MyForm = withFormik<MyFormProps, FormValues>({
  // Transform outer props into form values
  mapPropsToValues: (props) => {
    return {
      text: "",
      dueDate: new Date().toISOString(),
      reminder: false,
    };
  },

  // Add a custom validation function (this can be async too!)
  validate: (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};
    // if (!values.email) {
    //   errors.email = "Required";
    // }
    return errors;
  },
  validationSchema: TaskSchema,
  handleSubmit: async (values, formikBag) => {
    console.log(values);
    formikBag.props.addTaskMutation.mutate({
      text: values.text,
      dueDate: values.dueDate,
      reminder: values.reminder,
    });

    // console.log(formikBag.props.addTaskMutation.status);

    // if (formikBag.props.addTaskMutation.isSuccess) {
    //   formikBag.props.handleResult({
    //     success: true,
    //     message: `Task added successfully!`,
    //     task: formikBag.props.addTaskMutation.data,
    //   });
    // } else {
    //   formikBag.props.handleResult({
    //     success: false,
    //     message: `Unable to add task. (${formikBag.props.addTaskMutation.error})`,
    //   });
    // }

    // formikBag.setSubmitting(false);
  },
})(InnerForm);

interface AddTaskFormProps {
  onAddTask: (task: TaskType) => void;
}

const AddTaskForm = ({ onAddTask }: AddTaskFormProps) => {
  const [addTaskResult, setAddTaskResult] = useState<Result | undefined>(
    undefined
  );

  const addTaskUrl = `${process.env.REACT_APP_API_URL}/tasks/`;
  const { state, dispatch } = useAuth();

  const queryClient = useQueryClient();

  const mutation = useMutation<TaskType, unknown, TaskTypeNoId, unknown>(
    async (newTask: TaskTypeNoId) => {
      const response = await fetch(addTaskUrl, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(newTask),
        // body data type must match "Content-Type" header
      });
      console.log(response);

      const data = await response.json();
      console.log(data);
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries("tasks"),
    }
  );

  return (
    <>
      <DatePickerStyle />
      <MyForm
        message="Add Task"
        addTaskMutation={mutation}
        handleResult={(result) => {
          // console.log(result);
          // setAddTaskResult(result);
          // if (result.success && result.task) {
          // console.log("invalidating queries");
        }}
      />
      {console.log(mutation)}
      {(mutation.isSuccess || mutation.isError) && (
        <Alert status={mutation.isSuccess ? "success" : "error"} mt={4}>
          <AlertIcon />
          {mutation.isSuccess ? (
            <AlertTitle mr={2}>Adding Task Succeeded!</AlertTitle>
          ) : (
            <AlertTitle
              mr={2}
            >{`Adding Task Failed! (${mutation.error})`}</AlertTitle>
          )}
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => mutation.reset()}
          />
        </Alert>
      )}
    </>
  );
};

export default AddTaskForm;
