import { Center, Heading } from "@chakra-ui/react";

import Tasks from "./Tasks";

import { useAuth } from "../AuthProvider";

const TasksWrapper = () => {
  const { state, dispatch } = useAuth();
  console.log(state);
  return (
    <>
      {state.token ? (
        <Tasks />
      ) : (
        <Center>
          <Heading>Please log in to view and create tasks.</Heading>
        </Center>
      )}
    </>
  );
};

export default TasksWrapper;
