import { TaskType, TaskTypeNoId } from "../App";
import Task from "./Task";
import Header from "./Header";
import { useEffect, useState } from "react";
import { Box, Center } from "@chakra-ui/react";

import { useQuery, useMutation, useQueryClient } from "react-query";
import NotVerified from "./NotVerified";

import { useAuth } from "../AuthProvider";
import AddTaskForm from "./AddTaskForm";
import { NotVerifiedError, InvalidUserError } from "../utilities/Exceptions";

import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

interface Props {
  tasks: TaskType[];
  onRemove(id: number): void;
  toggleReminder(id: number): void;
}
// css in js with style-components
const style = {
  // backgroundColor: "red",
  // border: "1px dashed black",
  // h1: {
  //   backgroundColor: "green"
  // }
};

const getTasks = async (auth: string): Promise<TaskType[]> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/tasks/`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  const data = await res.json();
  console.log(res.status);
  console.log(data);
  if ([400, 401].includes(res.status)) {
    if (data.hasOwnProperty("detail")) {
      switch (data?.detail) {
        case "User not verified":
          console.log("User not verified");
          throw new NotVerifiedError("User not verified");
        case "Could not validate credentials":
          console.log("Invalid user");
          throw new InvalidUserError("User credentials invalid");
      }
    }
  }
  return data;
};

interface ToggleReminderMutationPayload {
  taskId: number;
  reminder: boolean;
}

const defaultProps: Props = {
  tasks: [],
  onRemove: (id) => console.log(id),
  toggleReminder: (id) => console.log(id),
};

const sortFunction = (a: TaskType, b: TaskType): number => {
  const aDate = dayjs(a.dueDate, "YYYY-MM-DDThh:mm:ss");
  const bDate = dayjs(b.dueDate, "YYYY-MM-DDThh:mm:ss");
  const currentDate = dayjs();
  if (a.reminder && !b.reminder) return -1;
  else if (!a.reminder && b.reminder) return 1;
  else {
    if (bDate.unix() - currentDate.unix() < aDate.unix() - currentDate.unix())
      return -1;
    else return 1;
  }
};

const Tasks = ({ onRemove, toggleReminder }: Props) => {
  const deleteTaskUrl = `${process.env.REACT_APP_API_URL}/tasks/`;

  const { state, dispatch } = useAuth();
  const [showAddTasks, setShowAddTasks] = useState(false);

  const queryClient = useQueryClient();

  const { status, data, isFetching, error } = useQuery<TaskType[], Error>(
    "tasks",
    async () => {
      try {
        const data = await getTasks(state.token as string);
        if (!state.verified) dispatch({ type: "verify" });
        return data;
      } catch (error) {
        console.log("error", error);
        return [];
      }
    }
  );

  const deleteMutation = useMutation<number, unknown, number, unknown>(
    async (taskId: number) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tasks/${taskId}`,
        {
          method: "DELETE", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            // "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      console.log(response);

      const data = await response.json();
      console.log(data);
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries("tasks"),
    }
  );

  const toggleReminderMutation = useMutation<
    unknown,
    unknown,
    ToggleReminderMutationPayload,
    unknown
  >(
    async ({ taskId, reminder }) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tasks/${taskId}`,
        {
          method: "PATCH", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            // "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${state.token}`,
          },
          body: JSON.stringify({
            reminder,
          }),
        }
      );
      const data = await response.json();
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries("tasks"),
    }
  );

  useEffect(() => {
    console.log("Tasks rerendering");
  });

  if (status === "loading") {
    return <div>loading...</div>; // loading state
  }

  if (status === "error") {
    return <div>{error?.message}</div>; // error state
  }

  return (
    <>
      {console.log(state)}
      {!state.verified ? (
        <NotVerified />
      ) : (
        <>
          <Header
            showingAddTasks={showAddTasks}
            toggleAddTasks={() => setShowAddTasks(!showAddTasks)}
          />
          {showAddTasks && (
            <Center>
              <Box mt={2} boxShadow="base" p={4} width="100%">
                <AddTaskForm
                  onAddTask={() => console.log("trying to add task")}
                />
              </Box>
            </Center>
          )}
          {console.log(data)}
          {data && data.length > 0
            ? data.sort(sortFunction).map((task) => {
                console.log(task);
                return (
                  <Task
                    key={task.id}
                    task={task}
                    onRemove={() => {
                      deleteMutation.mutate(task.id);
                    }}
                    toggleReminder={() =>
                      toggleReminderMutation.mutate({
                        taskId: task.id,
                        reminder: !task.reminder,
                      })
                    }
                  />
                );
              })
            : "No tasks."}
        </>
      )}
    </>
  );
};

Tasks.defaultProps = defaultProps;

export default Tasks;
