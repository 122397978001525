// import React from 'react', don't need this on newer versions of react
import styled from "styled-components";

import { Button, Flex, Spacer, Heading } from "@chakra-ui/react";

// import Button from "./Button";

import { useLocation } from "react-router-dom";

interface Props {
  title: string;
  className?: string;
  toggleAddTasks: Function;
  showingAddTasks: boolean;
}
// css in js with style-components
const style = {
  // backgroundColor: "red",
  // border: "1px dashed black",
  // h1: {
  //   backgroundColor: "green"
  // }
};

const defaultProps: Props = {
  title: "Task Tracker",
  showingAddTasks: false,
  toggleAddTasks: () => console.log("showing task form"),
};

const Header = ({ toggleAddTasks, showingAddTasks }: Props) => {
  const location = useLocation();

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    toggleAddTasks();
  };

  const headerTitle = () => {
    console.log(location.pathname);
    switch (location.pathname) {
      case "/":
        return "Your Tasks";
      case "/about":
        return "About";
      case "/login":
        return "Login";
    }
  };

  return (
    <header className="header">
      {location.pathname === "/" && (
        <Flex>
          <Heading size="md">{headerTitle()}</Heading>
          <Spacer />
          <Button
            size="sm"
            onClick={onClick}
            colorScheme={showingAddTasks ? "gray" : "green"}
          >
            {showingAddTasks ? "Hide" : "Add Task"}
          </Button>
        </Flex>
      )}
    </header>
  );
};

Header.defaultProps = defaultProps;

export default styled(Header)(style);
